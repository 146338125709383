<template lang="">
  <div>
    <div class="row">
      <b-alert :show="!areGoalsLocked" class="custom-info"
        ><strong>Note:</strong> Blue labels show current year goals. Click a label to use current year values for the
        upcoming year.</b-alert
      >
      <div class="col-sm-6">
        <div class="heading-bordered">
          Gideon
        </div>

        <div class="widget">
          <div class="widget__row" v-for="(goal, i) in gideonFormData" :key="goal.shortCode">
            <div class="row__column">
              <img
                v-if="hasTooltipMessage(goal.shortCode)"
                src="@/assets/svgs/goal-planner/ic_info.svg"
                alt="arrow"
                v-b-tooltip.hover="{ customClass: 'custom-tooltip custom-tooltip--align-lft', html: true }"
                :title="getToolTipMessage(goal.shortCode)"
              />
              {{ goal.goalName }}
            </div>
            <div class="row__column">
              <button
                :disabled="isCampGoalReadOnly"
                @click="gideonTransferButtonHandler(goal.shortCode, goal.fiscalYear1Goal)"
                type="button"
                class="btn btn--blue"
              >
                {{ formatNumber(goal.fiscalYear1Goal) }}
              </button>
            </div>
            <div class="row__column__wrap">
              <div
                :class="[
                  goal.planGoal > 100000 ? 'row__column row__column--pb' : 'row__column',
                  $v.gideonFormData.$each[i].$invalid ? 'row__column row__column--pb-sm' : 'row__column'
                ]"
              >
                <label v-if="isCampGoalReadOnly">{{ goal.planGoal }}</label>
                <GoalBaseInput
                  v-else
                  v-model="goal.planGoal"
                  :maximumDigitsAllowed="maximumNoOfDigitsAllowed"
                  @blur="handleBlur(i)"
                  :class="[
                    goal.planGoal > 100000 || $v.gideonFormData.$each[i].$invalid
                      ? 'text-right px-2 border-red'
                      : 'text-right px-2'
                  ]"
                />
                <div v-if="goal.planGoal > 100000" class="error-placement">
                  The maximum limit allowed is 100,000.
                </div>
                <div v-if="$v.gideonFormData.$each[i].$invalid" class="error-placement">
                  Enter a valid value
                </div>
              </div>
              <div class="row__column">
                <button
                  :disabled="isCampGoalReadOnly"
                  @click="
                    monthlyAllocationHandler({
                      shortCode: goal.shortCode,
                      fiscalYear1Goal: goal.fiscalYear1Goal,
                      planGoal: goal.planGoal
                    })
                  "
                  type="button"
                  class="btn btn-primary"
                >
                  <img src="@/assets/svgs/goal-planner/ic_monthly.svg" alt="Monthly" /> Monthly
                </button>
              </div>
            </div>
          </div>
          <div class="widget__footer-row" v-if="displayTotalSection">
            <div class="footer-row__heading">Gideon Subtotal</div>
            <div class="footer-row__total-col">{{ calculatedGideonSubTotal }}</div>
          </div>
          <div class="widget__footer-row" v-if="displayTotalSection">
            <div class="footer-row__heading">Current Year Goal</div>
            <div class="footer-row__total-col">{{ gideonCurrentYearGoal }}</div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="heading-bordered">
          Auxiliary
        </div>
        <div class="widget">
          <div class="widget__row" v-for="(goal, i) in auxiliaryFormData" :key="goal.shortCode">
            <div class="row__column">
              <img
                v-if="hasTooltipMessage(goal.shortCode)"
                src="@/assets/svgs/goal-planner/ic_info.svg"
                alt="arrow"
                v-b-tooltip.hover="{ customClass: 'custom-tooltip custom-tooltip--align-lft', html: true }"
                :title="getToolTipMessage(goal.shortCode)"
              />
              {{ goal.goalName }}
            </div>
            <div class="row__column">
              <button
                @click="auxiliaryTransferButtonHandler(goal.shortCode, goal.fiscalYear1Goal)"
                :disabled="isCampGoalReadOnly"
                type="button"
                class="btn btn--blue"
              >
                {{ formatNumber(goal.fiscalYear1Goal) }}
              </button>
            </div>
            <div class="row__column__wrap">
              <div
                :class="[
                  goal.planGoal > 100000 ? 'row__column row__column--pb' : 'row__column',
                  $v.auxiliaryFormData.$each[i].$invalid ? 'row__column row__column--pb-sm' : 'row__column'
                ]"
              >
                <label v-if="isCampGoalReadOnly">{{ goal.planGoal }}</label>
                <GoalBaseInput
                  v-else
                  v-model="goal.planGoal"
                  :maximumDigitsAllowed="maximumNoOfDigitsAllowed"
                  @blur="handleBlurAuxiliary(i)"
                  :class="[
                    goal.planGoal > 100000 || $v.auxiliaryFormData.$each[i].$invalid
                      ? 'text-right px-2 border-red'
                      : 'text-right px-2'
                  ]"
                />
                <div v-if="goal.planGoal > 100000" class="error-placement">
                  The maximum limit allowed is 100,000.
                </div>
                <div v-if="$v.auxiliaryFormData.$each[i].$invalid" class="error-placement">
                  Enter a valid value
                </div>
              </div>
              <div class="row__column">
                <button
                  :disabled="isCampGoalReadOnly"
                  @click="
                    monthlyAllocationHandler({
                      shortCode: goal.shortCode,
                      fiscalYear1Goal: goal.fiscalYear1Goal,
                      planGoal: goal.planGoal
                    })
                  "
                  type="button"
                  class="btn btn-primary"
                >
                  <img src="@/assets/svgs/goal-planner/ic_monthly.svg" alt="Monthly" /> Monthly
                </button>
              </div>
            </div>
          </div>
          <div class="widget__footer-row" v-if="displayTotalSection">
            <div class="footer-row__heading">Auxiliary Subtotal</div>
            <div class="footer-row__total-col">{{ calculatedAuxiliarySubTotal }}</div>
          </div>
          <div class="widget__footer-row" v-if="displayTotalSection">
            <div class="footer-row__heading">Current Year Goal</div>
            <div class="footer-row__total-col">{{ auxiliaryCurrentYearGoal }}</div>
          </div>
        </div>
      </div>

      <customize-monthly-allocation
        v-if="modalShow"
        :isModalShow="modalShow"
        :isGoalReadOnlyMode="isCampGoalReadOnly"
        :monthlyAllocationTotalData="placementsCustomizedMAData"
        :column2GoalShortCode="modalColumn2GoalShortCode"
        @column2-total-changed="modalTotalChangeHandler"
        @close-modal="modalCloseHandler"
      />
    </div>
    <div class="h-footer">
      <button v-if="!isCampGoalReadOnly" type="button" @click="saveAndNavigateHandler" class="btn btn-primary">
        <img src="@/assets/svgs/goal-planner/ic_save.svg" alt="save" />Save and Continue
      </button>
      <button v-else type="button" @click="navigateToNextPage" class="btn btn-primary">
        Continue
      </button>
      <button v-if="!isCampGoalReadOnly" type="button" @click="navigateToNextPage" class="btn btn-primary btn--blue">
        <img src="@/assets/svgs/goal-planner/ic_skip.svg" alt="skip" />Skip
      </button>
      <button type="button" @click="navigateToDashboard()" class="btn btn-primary">
        <img src="@/assets/svgs/goal-planner/ic_return.svg" alt="return" />Return to Dashboard
        {{ isCampGoalReadOnly ? '' : 'w/out Saving' }}
      </button>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import CustomizeMonthlyAllocation from '@/components/camp-goal-planner/CustomizeMonthlyAllocation.vue'
import GoalBaseInput from './GoalBaseInput.vue'
import { NextCampGoalRoute } from '@/services/utils/NextCampGoalRoute'
import { CampGoalsAllowedMaximumDigits } from '@/constants/CampGoalsMaximumBoundConstants.js'
import { GoalShortCodes } from '@/constants/GoalShortCodes.js'
import { GoalsConstants, MedicalTestaments, OtherTestaments } from '@/constants/GoalsConstants.js'
import { PermittedGoalRouteNames } from '@/constants/PermittedGoalRouteNames.js'
import { mapGetters, mapActions } from 'vuex'
import { numeric, required } from 'vuelidate/lib/validators'
import { FormatNumber } from '@/services/utils/FormatNumber.js'
import { ToolTipsMessage } from '@/services/utils/TooltipsMessages.js'
import { GoalsWithTooltips } from '@/constants/GoalsWithTooltips.js'
import { STATE_GOAL_PLANNER_MENU_HREF } from '@/constants/UserMenuHref.js'

export default {
  name: 'GoalPlanningPlacements',
  props: {
    goalDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalShow: false,
      modalColumn2GoalShortCode: '',
      placementsCustomizedMAData: {
        column2Total: null,
        column2FY1GoalTotal: null,
        column2GoalShortCode: null
      },
      gideonFormData: [
        {
          planGoal: 0
        }
      ],
      auxiliaryFormData: [{ planGoal: 0 }]
    }
  },
  validations: {
    gideonFormData: {
      $each: {
        planGoal: { numeric, required }
      }
    },
    auxiliaryFormData: {
      $each: {
        planGoal: { numeric, required }
      }
    }
  },
  computed: {
    ...mapGetters({
      officerToolbarSelected: 'user/officerToolbarSelected',
      placementsGoals: 'campGoalPlanner/placementsGoals',
      isCampGoalPlanningWindowOpen: 'campGoalPlanner/isCampGoalPlanningWindowOpen',
      personalWorkersTestamentsGoals: 'campGoalPlanner/personalWorkersTestamentsGoals',
      isStateGoalPlanningWindowOpen: 'stateGoalPlanner/isStateGoalPlanningWindowOpen',
      isInMenu: 'menu/isInMenu'
    }),
    isUserHasStateGoalPlannerAccessInEditMode() {
      return this.isInMenu(STATE_GOAL_PLANNER_MENU_HREF) && this.isStateGoalPlanningWindowOpen
    },
    isCampGoalReadOnly() {
      //NOTE: if user has state level role then should let user be in edit mode
      // Goals are locked
      if (this.areGoalsLocked) {
        return true
      } else if (!this.isUserHasStateGoalPlannerAccessInEditMode && !this.isCampGoalPlanningWindowOpen) {
        // login user is a camp leader and state window open and camp window is closed
        return true
      } else if (this.isUserHasStateGoalPlannerAccessInEditMode && !this.isCampGoalPlanningWindowOpen) {
        // login user is a state leader and state window open and camp window is closed
        return false
      }
      // when camp window is open
      return false
    },
    userCampKey() {
      return this.officerToolbarSelected.camp
    },
    formatNumber() {
      return value => FormatNumber(value)
    },
    displayTotalSection() {
      return this.$route.params.campGoalName !== PermittedGoalRouteNames.PersonalWorkersTestaments
    },
    hasFormValidationSuccessful() {
      return !this.$v.gideonFormData.$invalid && !this.$v.auxiliaryFormData.$invalid
    },
    areGoalsLocked() {
      return this.goalDetails.hasOwnProperty('areGoalsLocked') && this.goalDetails.areGoalsLocked
    },
    maximumNoOfDigitsAllowed() {
      return CampGoalsAllowedMaximumDigits.CampNonDollarSubGoalPlacementsAndPWTs
    },
    calculatedGideonSubTotal() {
      let total = 0
      this.gideonFormData &&
        this.gideonFormData.forEach(itm => {
          if (typeof itm.planGoal === 'number') {
            total += itm.planGoal
          }
        })
      return FormatNumber(total)
    },
    calculatedAuxiliarySubTotal() {
      let total = 0
      this.gideonFormData &&
        this.auxiliaryFormData.forEach(itm => {
          if (typeof itm.planGoal === 'number') {
            total += itm.planGoal
          }
        })
      return FormatNumber(total)
    },
    auxiliaryCurrentYearGoal() {
      return (
        this.goalDetails.hasOwnProperty('auxiliary') &&
        this.goalDetails.auxiliary.hasOwnProperty('fiscalYear1GoalsTotal') &&
        this.goalDetails.auxiliary.fiscalYear1GoalsTotal
      )
    },
    gideonCurrentYearGoal() {
      return (
        this.goalDetails.hasOwnProperty('gideon') &&
        this.goalDetails.gideon.hasOwnProperty('fiscalYear1GoalsTotal') &&
        this.goalDetails.gideon.fiscalYear1GoalsTotal
      )
    }
  },

  methods: {
    ...mapActions({
      resetPWTsCustomizeMonthlyAllocationData: 'campGoalPlanner/resetPWTsCustomizeMonthlyAllocationData',
      sendPlacementsGoalsData: 'campGoalPlanner/sendPlacementsGoalsData',
      sendPersonalWorkersTestamentsGoalsData: 'campGoalPlanner/sendPersonalWorkersTestamentsGoalsData',
      resetPlacementsCustomizeMonthlyAllocationData: 'campGoalPlanner/resetPlacementsCustomizeMonthlyAllocationData',
      setCampGoalsProgressStatus: 'campGoalPlanner/setCampGoalsProgressStatus'
    }),
    hasTooltipMessage(shortCode) {
      return GoalsWithTooltips.includes(shortCode)
    },
    getToolTipMessage(shortCode) {
      return ToolTipsMessage(shortCode)
    },
    modalTotalChangeHandler(column2TotalValue, shortCode) {
      this.gideonFormData.forEach(itm => {
        if (itm.shortCode === shortCode) {
          itm.planGoal = column2TotalValue
        }
      })
      this.auxiliaryFormData.forEach(itm => {
        if (itm.shortCode === shortCode) {
          itm.planGoal = column2TotalValue
        }
      })
    },
    async monthlyAllocationHandler({ shortCode, fiscalYear1Goal, planGoal }) {
      this.modalColumn2GoalShortCode = shortCode
      this.placementsCustomizedMAData = {
        column2Total: planGoal,
        column2FY1GoalTotal: fiscalYear1Goal
      }
      this.modalShow = true
    },
    gideonTransferButtonHandler(shortCode, value) {
      if (this.isCampGoalReadOnly) {
        return
      }
      this.gideonFormData.forEach(goal => {
        if (goal.shortCode === shortCode) {
          goal.planGoal = value
        }
      })
    },
    auxiliaryTransferButtonHandler(shortCode, value) {
      if (this.isCampGoalReadOnly) {
        return
      }
      this.auxiliaryFormData.forEach(goal => {
        if (goal.shortCode === shortCode) {
          goal.planGoal = value
        }
      })
    },
    modalHandler() {
      this.modalShow = true
    },
    modalCloseHandler() {
      this.modalShow = false
      this.radioValue = GoalsConstants.SpreadEqually
    },
    navigateToDashboard() {
      this.$router.push({ name: 'goal-planner-cm', params: { campGoalName: 'dashboard' } })
    },
    async saveAndNavigateHandler() {
      const payload = {
        CampOrgKey: this.userCampKey
      }
      if (this.isCampGoalReadOnly) {
        return
      }
      this.gideonFormData.forEach(goal => {
        switch (goal.shortCode) {
          case GoalShortCodes.Bible:
            payload.Bible = goal.planGoal
            break
          case GoalShortCodes.Hospital:
            payload.Hospital = goal.planGoal
            break
          case GoalShortCodes.College:
            payload.College = goal.planGoal
            break
          case GoalShortCodes.Youth:
            payload.Youth = goal.planGoal
            break
          case GoalShortCodes.SidewalkYouth:
            payload.SidewalkYouth = goal.planGoal
            break
          case GoalShortCodes.Service:
            payload.Service = goal.planGoal
            break
          case GoalShortCodes.SoftCoverBible:
            payload.SoftCoverBible = goal.planGoal
            break
          case GoalShortCodes.GideonFacilities:
            payload.GideonFacilities = goal.planGoal
            break
          case GoalShortCodes.GideonPWTs:
            payload.GideonPWT = goal.planGoal
            break
          default:
            break
        }
      })
      this.auxiliaryFormData.forEach(goal => {
        switch (goal.shortCode) {
          case GoalShortCodes.Medical:
            payload.Medical = goal.planGoal
            break
          case GoalShortCodes.AuxiliaryFacilities:
            payload.AuxiliaryFacilities = goal.planGoal
            break
          case GoalShortCodes.AuxiliaryFacilities9:
            payload.AuxiliaryFacilities = goal.planGoal
            break
          case GoalShortCodes.AuxiliaryPWTs:
            payload.AuxiliaryPWT = goal.planGoal
            break
          default:
            break
        }
      })
      if (this.hasFormValidationSuccessful) {
        let response
        if (this.$route.params.campGoalName === PermittedGoalRouteNames.Placements) {
          response = await this.sendPlacementsGoalsData(payload)
        } else if (this.$route.params.campGoalName === PermittedGoalRouteNames.PersonalWorkersTestaments) {
          response = await this.sendPersonalWorkersTestamentsGoalsData(payload)
        }
        if (response === 'Success') {
          if (this.$route.params.campGoalName === PermittedGoalRouteNames.Placements) {
            // resetting placements customize monthly allocation persisted data
            this.resetPlacementsCustomizeMonthlyAllocationData()
          } else if (this.$route.params.campGoalName === PermittedGoalRouteNames.PersonalWorkersTestaments) {
            // resetting PWTs customize monthly allocation persisted data
            this.resetPWTsCustomizeMonthlyAllocationData()
          }

          // calling stepper GET api after successful goal update
          await this.setCampGoalsProgressStatus({ campKey: this.userCampKey })
          this.navigateToNextPage()
        }
      }
    },
    navigateToNextPage() {
      this.$router.push({
        name: 'goal-planner-cm',
        params: { campGoalName: NextCampGoalRoute(this.$route.params.campGoalName) }
      })
    },
    getPlacementDetails(goalDetails) {
      this.gideonFormData = goalDetails.hasOwnProperty('gideon') && window.structuredClone(goalDetails.gideon.goalsData)
      this.auxiliaryFormData =
        goalDetails.hasOwnProperty('auxiliary') && window.structuredClone(goalDetails.auxiliary.goalsData)
    },
    handleBlur(i) {
      this.updatePlanGoal(this.gideonFormData, i)
    },

    handleBlurAuxiliary(i) {
      this.updatePlanGoal(this.auxiliaryFormData, i)
    },

    updatePlanGoal(formData, i) {
      const goalName = formData[i].goalName
      let roundingValue = 25

      if (MedicalTestaments.includes(goalName)) {
        roundingValue = 50
      } else if (OtherTestaments.includes(goalName)) {
        roundingValue = 100
      }
      const maxAllowedValue = 100000
      if (formData[i].planGoal > maxAllowedValue) {
        formData[i].planGoal = maxAllowedValue
      }
      formData[i].planGoal = this.roundToNearest(formData[i].planGoal, roundingValue)
    },

    roundToNearest(value, multiple) {
      return Math.ceil(value / multiple) * multiple
    }
  },
  watch: {
    goalDetails: {
      handler(newGoalDetails) {
        this.getPlacementDetails(newGoalDetails)
      },
      deep: true
    }
  },

  created() {
    this.getPlacementDetails(this.goalDetails)
  },
  components: {
    CustomizeMonthlyAllocation,
    GoalBaseInput
  }
}
</script>
<style lang="scss">
.error-placement {
  display: block;
  color: #df1515;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  margin-left: 0;
  margin-top: 5px;
}

.h-footer {
  background: #e0e8ef;
  display: flex;
  justify-content: flex-end;
  padding: 18px 38px;
  margin: 0 -38px;

  .btn {
    margin-left: 15px;
    text-transform: none;
    padding: 11px 15px;
    display: flex;
    align-items: center;

    &.btn--blue {
      background: #0097b9;

      &:hover {
        background: #003946;
      }
    }

    img {
      margin-right: 8px;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.custom-info {
  width: max-content;
  padding: 0.6em 1em;
  margin: 1em 0 0 1em;
}

.widget {
  margin-bottom: 40px;

  .widget__row {
    display: flex;
    justify-content: space-between;
    padding: 7px 15px;
    background: #fff;

    &:nth-child(even) {
      background: #ececec;
    }

    .row__column__wrap {
      display: flex;
      position: relative;
      justify-content: space-between;

      .row__column {
        &:first-child {
          width: 100px;
          margin-left: 10px;
        }

        .btn {
          margin-left: 16px;
        }
      }
    }

    .row__column {
      font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
      font-size: 16px;

      input[type='text'].border-red,
      input[type='text'].border-red:focus-visible {
        border-color: #df1515 !important;
        outline-color: #df1515;
        border-radius: 3px;
      }

      .column__align-center {
        display: flex;
        align-items: center;
        padding-top: 9px;

        img {
          margin-right: 5px;
        }
      }

      &.row__column--pb {
        padding-bottom: 35px;
      }

      &.row__column--pb-sm {
        padding-bottom: 20px;
      }

      &:first-child {
        width: 170px;
      }

      .btn {
        padding: 10px 15px;
        text-transform: capitalize;
        font-weight: normal;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img {
          margin-right: 7px;
        }

        &:disabled {
          cursor: not-allowed;
          background-color: #003946;
        }

        &.btn--blue {
          background: #01a9cf;
          border-radius: 3px;
          color: #fff;
          width: 60px;
          padding: 8px 10px;
          font-size: 16px;
          font-weight: bold;
        }
      }

      input[type='text'] {
        max-width: 100px;
        min-height: 38px;
      }
    }

    .error-placement {
      font-size: 13px;
      position: absolute;
      line-height: 16px;
    }
  }

  .widget__footer-row {
    display: flex;
    font-weight: bold;
    color: #000;
    padding: 5px 15px 2px;

    .footer-row__heading {
      min-width: 220px;
    }

    .footer-row__total-col {
      text-align: right;
      width: 120px;
    }
  }
}
</style>
