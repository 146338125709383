<template lang="">
  <span
    v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
    title="Click to plan"
    @mouseover="startButtonHoverHandler('hover')"
    @mouseleave="startButtonHoverHandler('leave')"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="play-icon" viewBox="0 0 17 17">
      <path
        id="play_circle_FILL1_wght400_GRAD0_opsz48"
        d="M86.439-867.887l5.674-3.613-5.674-3.612ZM88.5-863a8.224,8.224,0,0,1-3.294-.669A8.622,8.622,0,0,1,82.5-865.5a8.622,8.622,0,0,1-1.827-2.709A8.225,8.225,0,0,1,80-871.5a8.277,8.277,0,0,1,.669-3.315,8.495,8.495,0,0,1,1.827-2.7,8.712,8.712,0,0,1,2.709-1.817A8.224,8.224,0,0,1,88.5-880a8.276,8.276,0,0,1,3.315.669,8.582,8.582,0,0,1,2.7,1.817,8.584,8.584,0,0,1,1.817,2.7A8.277,8.277,0,0,1,97-871.5a8.225,8.225,0,0,1-.669,3.294,8.714,8.714,0,0,1-1.817,2.709,8.495,8.495,0,0,1-2.7,1.827A8.276,8.276,0,0,1,88.5-863Z"
        transform="translate(-80 880)"
        :fill="fillColor"
      />
    </svg>
  </span>
</template>
<script>
/*eslint-disable*/
export default {
  data() {
    return {
      fillColor: '#000'
    }
  },
  methods: {
    startButtonHoverHandler(eventName) {
      if (eventName === 'hover') {
        this.fillColor = '#fbc301'
      }
      if (eventName === 'leave') this.fillColor = '#000'
    }
  }
}
</script>
<style lang="scss" scoped>
.play-icon {
  cursor: pointer;
}
</style>
