<template lang="">
  <section>
    <div class="planner-header">
      <h4>{{ goalCategory }}</h4>
      <slot name="header-buttons"></slot>
    </div>
    <b-table class="custom-table" striped hover :fields="fields" :items="campGoalData" :tbody-tr-class="rowClass">
      <template #cell(goalNameWithStatus)="data">
        <span v-if="isBarnabasFund(data.item.shortCode)" class="row-inherited__cell"></span>
        {{ data.value
        }}<span v-if="data.item.isGoalCompleted && !data.item.isCampLocked" class="badge badge--success">
          Completed</span
        >
      </template>

      <template #cell(goalAction)="data">
        <span v-if="!isBarnabasFund(data.item.shortCode)">
          <span v-if="!data.item.isCampLocked && (isCampGoalPlanningWindowOpen || isUserHasStateGoalPlannerAccessInEditMode)">
            <span
              v-if="data.item.isGoalCompleted"
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              title="Click to edit"
              @click="goalActionHandler(data.value.routePath)"
              class="edit-circle"
              ><img src="@/assets/svgs/goal-planner/edit.svg" alt="edit"
            /></span>
            <span v-else @click="goalActionHandler(data.value.routePath)">
              <start-icon></start-icon>
            </span>
          </span>
          <span
            class="edit-circle"
            v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
            title="Click to view"
            v-else
            @click="goalActionHandler(data.value.routePath)"
          >
            <img src="@/assets/svgs/goal-planner/ic_eye.svg" width="16" alt="eye" />
          </span>
        </span>
      </template>
      <template #custom-foot>
        <slot name="table-total"></slot>
      </template>
    </b-table>
  </section>
</template>
<script>
/*eslint-disable*/
import StartIcon from '@/components/camp-goal-planner/StartIcon.vue'
import { GoalShortCodes } from '@/constants/GoalShortCodes.js'
import { mapGetters } from 'vuex'
import { STATE_GOAL_PLANNER_MENU_HREF } from '@/constants/UserMenuHref.js'

export default {
  props: {
    lastThreeFYs: {
      type: Object,
      required: true
    },
    campGoalData: {
      type: Array,
      required: true
    },
    goalCategory: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      startButtonFillColor: '#000',
      fields: [
        {
          key: 'goalNameWithStatus',
          label: ''
        },
        {
          key: 'campGoal',
          label: 'Camp Goal',
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: (val, key, item) => (val === null ? 'N/A' : val)
        },
        {
          key: 'lastFYGoal',
          label: `${this.lastThreeFYs.lastFY} Goal`,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: (val, key, item) => (val === null ? 'N/A' : val)
        },
        {
          key: 'secondLastFYGoal',
          label: `${this.lastThreeFYs.secondLastFY} Actual`,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: (val, key, item) => (val === null ? 'N/A' : val)
        },
        {
          key: 'thirdLastFYGoal',
          label: `${this.lastThreeFYs.thirdLastFY} Actual`,
          thClass: 'text-right',
          tdClass: 'text-right',
          formatter: (val, key, item) => (val === null ? 'N/A' : val)
        },
        {
          key: 'goalAction',
          label: ' '
        }
      ]
    }
  },
  methods: {
    goalActionHandler(routePath) {
      this.$router.push({ name: 'goal-planner-cm', params: { campGoalName: routePath } })
    },
    isBarnabasFund(shortCode) {
      return shortCode === GoalShortCodes.BarnabasFund
    },
    isFaithFund(shortCode) {
      return shortCode === GoalShortCodes.FaithFund
    },
    rowClass(item, type) {
      if (item && type === 'row' && this.isFaithFund(item.shortCode)) {
        return 'row-inherited'
      } else {
        return null
      }
    }
  },
  computed: {
    ...mapGetters({
      isCampGoalPlanningWindowOpen: 'campGoalPlanner/isCampGoalPlanningWindowOpen',
      isStateGoalPlanningWindowOpen: 'stateGoalPlanner/isStateGoalPlanningWindowOpen',
      isInMenu: 'menu/isInMenu'
    }),
    isUserHasStateGoalPlannerAccessInEditMode() {
      return this.isInMenu(STATE_GOAL_PLANNER_MENU_HREF) && this.isStateGoalPlanningWindowOpen
    },
  },
  components: {
    StartIcon
  }
}
</script>
<style lang="scss">
.custom-table {
  th {
    background-color: #003b48;
    color: #fff;

    &:first-child {
      width: 350px;
    }
  }

  td {
    border-top: none;
  }

  .badge {
    position: relative;
    padding: 0.35rem 0.75rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    display: inline-block;
    margin-left: 10px;
    font-size: 13px;
    font-weight: normal;
  }

  .badge--success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .edit-circle {
    width: 24px;
    height: 24px;
    background: #000;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #fbc301;
    }
  }
}

.planner-header {
  margin: 30px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin-bottom: 0;

    span {
      font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
      font-size: 20px;
      font-weight: 700;
      color: #747474;
      text-transform: capitalize;
      display: inline-flex;
      margin-left: 15px;
    }
  }

  .planner-header__lft {
    display: flex;

    a {
      &:first-child {
        margin-right: 13px;
      }
    }

    .btn {
      padding: 11px 13px;
      text-transform: capitalize;

      img {
        margin-right: 5px;
      }
    }
  }
}

.row-inherited {
  td {
    position: relative;

    &:first-child {
      &:after {
        content: '';
        border: 1px solid #000;
        position: absolute;
        bottom: -25px;
        height: 41px;
        left: 13px;
      }
    }
  }
}

.row-inherited__cell {
  display: inline-block;
  position: relative;
  width: 17px;
  height: 2px;
  background: #000;
  margin-left: 1px;
  top: -4px;
  margin-right: 3px;
}
</style>
