<template lang="">
  <div>
    <camp-goal-layout :goalName="pageGoalName">
      <template #goal-lock-warn-info>
        <b-alert :show="isCampGoalsLocked" variant="danger">
          GOALS HAVE BEEN LOCKED BY THE LOCAL CAMP. PLEASE DO NOT UNLOCK AND MAKE CHANGES WITHOUT FIRST DISCUSSING WITH
          THE CAMP PRESIDENT.
        </b-alert>
      </template>
      <template #body>
        <goal-planning-placements
          v-if="isNonReceiptsCategoryRoute"
          :goalDetails="getGoalsDataForNonReceiptsCategory($route.params.campGoalName)"
        />
        <goal-section v-else :goalDetails="getGoalsDataForReceiptsCategory($route.params.campGoalName)" />
      </template>
    </camp-goal-layout>
  </div>
</template>
<script>
/*eslint-disable*/
import CampGoalLayout from '@/components/camp-goal-planner/CampGoalLayout.vue'
import GoalSection from '@/components/camp-goal-planner/GoalSection.vue'
import GoalPlanningPlacements from '@/components/camp-goal-planner/GoalPlanningPlacements.vue'
import { PermittedGoalRouteNames } from '@/constants/PermittedGoalRouteNames.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    pageGoalName: {
      type: String,
      required: true
    }
  },
  provide() {
    return { goalPageTitle: this.pageGoalName }
  },
  data() {
    return {
      modalShow: false
    }
  },
  computed: {
    ...mapGetters({
      // CAMP GOALS GETTERS
      churchMinistryAndChurchPresentationGoals: 'campGoalPlanner/churchMinistryAndChurchPresentationGoals',
      gideonCardBibleGoals: 'campGoalPlanner/gideonCardBibleGoals',
      faithFundGoals: 'campGoalPlanner/faithFundGoals',
      auxiliaryScriptureGoals: 'campGoalPlanner/auxiliaryScriptureGoals',
      campDesignatedGoals: 'campGoalPlanner/campDesignatedGoals',
      placementsGoals: 'campGoalPlanner/placementsGoals',
      personalWorkersTestamentsGoals: 'campGoalPlanner/personalWorkersTestamentsGoals',
      membershipGoals: 'campGoalPlanner/membershipGoals',
      // OTHER GETTERS
      lockedGoalsAccessError: 'campGoalPlanner/lockedGoalsAccessError',
      officerToolbarSelected: 'user/officerToolbarSelected',
      userLanguageKey: 'user/userLanguageKey',
      isCampGoalPlanningWindowOpen: 'campGoalPlanner/isCampGoalPlanningWindowOpen'
    }),
    isCampGoalsLocked() {
      if (this.isNonReceiptsCategoryRoute) {
        return this.getGoalsDataForNonReceiptsCategory(this.$route.params.campGoalName).hasOwnProperty('areGoalsLocked')
          ? this.getGoalsDataForNonReceiptsCategory(this.$route.params.campGoalName).areGoalsLocked
          : false
      }
      return this.getGoalsDataForReceiptsCategory(this.$route.params.campGoalName).hasOwnProperty('areGoalsLocked')
        ? this.getGoalsDataForReceiptsCategory(this.$route.params.campGoalName).areGoalsLocked
        : false
    },
    campName() {
      return this.officerToolbarSelected.camp_name
    },
    campKey() {
      return this.officerToolbarSelected.camp
    },
    isNonReceiptsCategoryRoute() {
      const routeParam = this.$route.params.campGoalName
      return !!(
        routeParam === PermittedGoalRouteNames.Placements ||
        routeParam === PermittedGoalRouteNames.PersonalWorkersTestaments
      )
    },
    getGoalsDataForReceiptsCategory() {
      return routeGoalName => {
        return new Map([
          [PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation, this.churchMinistryAndChurchPresentationGoals],
          [PermittedGoalRouteNames.GideonCard, this.gideonCardBibleGoals],
          [PermittedGoalRouteNames.FaithFund, this.faithFundGoals],
          [PermittedGoalRouteNames.AuxiliaryScripture, this.auxiliaryScriptureGoals],
          [PermittedGoalRouteNames.CampDesignated, this.campDesignatedGoals],
          [PermittedGoalRouteNames.Membership, this.membershipGoals]
        ]).get(routeGoalName)
      }
    },
    getGoalsDataForNonReceiptsCategory() {
      return routeGoalName => {
        return new Map([
          [PermittedGoalRouteNames.Placements, this.placementsGoals],
          [PermittedGoalRouteNames.PersonalWorkersTestaments, this.personalWorkersTestamentsGoals]
        ]).get(routeGoalName)
      }
    }
  },

  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      setChurchMinistryAndChurchPresentationGoals: 'campGoalPlanner/setChurchMinistryAndChurchPresentationGoals',
      setGideonCardGoals: 'campGoalPlanner/setGideonCardGoals',
      setFaithFundGoals: 'campGoalPlanner/setFaithFundGoals',
      setAuxiliaryScriptureGoals: 'campGoalPlanner/setAuxiliaryScriptureGoals',
      setCampDesignatedGoals: 'campGoalPlanner/setCampDesignatedGoals',
      setPlacementsGoals: 'campGoalPlanner/setPlacementsGoals',
      setPersonalWorkersTestamentsGoals: 'campGoalPlanner/setPersonalWorkersTestamentsGoals',
      setMembershipGoals: 'campGoalPlanner/setMembershipGoals'
    }),
    fetchCorrespondingGoalsData(routeGoalName) {
      return new Map([
        [
          PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation,
          () =>
            this.setChurchMinistryAndChurchPresentationGoals({
              campKey: this.campKey,
              langKey: this.userLanguageKey
            })
        ],
        [
          PermittedGoalRouteNames.GideonCard,
          () =>
            this.setGideonCardGoals({
              campKey: this.campKey,
              langKey: this.userLanguageKey
            })
        ],
        [
          PermittedGoalRouteNames.FaithFund,
          () =>
            this.setFaithFundGoals({
              campKey: this.campKey,
              langKey: this.userLanguageKey
            })
        ],
        [
          PermittedGoalRouteNames.AuxiliaryScripture,
          () =>
            this.setAuxiliaryScriptureGoals({
              campKey: this.campKey,
              langKey: this.userLanguageKey
            })
        ],
        [
          PermittedGoalRouteNames.CampDesignated,
          () =>
            this.setCampDesignatedGoals({
              campKey: this.campKey,
              langKey: this.userLanguageKey
            })
        ],
        [
          PermittedGoalRouteNames.Placements,
          () =>
            this.setPlacementsGoals({
              campKey: this.campKey,
              langKey: this.userLanguageKey
            })
        ],
        [
          PermittedGoalRouteNames.Membership,
          () =>
            this.setMembershipGoals({
              campKey: this.campKey,
              langKey: this.userLanguageKey
            })
        ],
        [
          PermittedGoalRouteNames.PersonalWorkersTestaments,
          () =>
            this.setPersonalWorkersTestamentsGoals({
              campKey: this.campKey,
              langKey: this.userLanguageKey
            })
        ]
      ]).get(routeGoalName)
    }
  },
  watch: {
    campName: {
      async handler() {
        await this.fetchCorrespondingGoalsData(this.$route.params.campGoalName)()
      },
      deep: true
    }
  },
  async created() {
    await this.fetchCorrespondingGoalsData(this.$route.params.campGoalName)()
  },
  components: {
    CampGoalLayout,
    GoalPlanningPlacements,
    GoalSection
  }
}
</script>
<style lang="scss">
.card--pb-0 {
  padding-bottom: 0;
}
</style>
