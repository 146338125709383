<template lang="">
  <div>
    <b-alert v-if="hasErrorDashboard && !loadingStatus" style="margin-top:80px;" show variant="danger">
      <b-icon icon="exclamation-circle" class="warning-icon"></b-icon>
      There is no goals data in the database for the camp you are looking for. Please try selecting another
      camp.</b-alert
    >
    <camp-goal-layout v-if="hasErrorDashboard !== null && !hasErrorDashboard" goalName="dashboard">
      <template #goal-lock-warn-info>
        <b-alert :show="isCampGoalsLocked" variant="danger">
          GOALS HAVE BEEN LOCKED BY THE LOCAL CAMP. PLEASE DO NOT UNLOCK AND MAKE CHANGES WITHOUT FIRST DISCUSSING WITH
          THE CAMP PRESIDENT.
        </b-alert>
      </template>
      <template #progress-badge>
        <span class="camp-lock-text" v-if="isCampGoalsLocked"> - CAMP IS LOCKED</span>
        <span class="badge badge--success">({{ goalsPercentComplete }}% Completed)</span>
      </template>
      <template #body>
        <dashboard-table
          v-for="(GOAL_CAT_KEY, GOAL_CAT_NAME) in GOAL_CATEGORY_KEYS"
          :key="GOAL_CAT_KEY"
          :lastThreeFYs="getLastThreeFYs"
          :goalCategory="getTableGoalCategory(GOAL_CAT_KEY) || ''"
          :campGoalData="getDashboardTableData(GOAL_CAT_KEY) || ''"
        >
          <template v-if="GOAL_CAT_NAME === 'RECEIPTS'" #header-buttons>
            <div class="planner-header__lft">
              <a
                href="#"
                @click.prevent="
                  downloadCampLockGoalsReport({
                    campKey: campKey,
                    fiscalYear: currentFiscalYear,
                    campName: campName
                  })
                "
                class="btn btn-primary btn--lgt-blue"
              >
                <img src="@/assets/svgs/goal-planner/ic_download.svg" alt="Lock Goals Report" /> Camp Lock Goals Report
              </a>

              <button
                v-if="!isCampGoalsLocked && isCampGoalReadOnly"
                type="button"
                :disabled="true"
                class="btn btn-primary btn--yellow"
              >
                <img src="@/assets/svgs/goal-planner/ic_play.svg" alt="Begin Goal Planning" />
                Begin Goal Planning
              </button>

              <router-link
                v-else-if="!isCampGoalReadOnly"
                :to="{
                  name: 'goal-planner-cm',
                  params: { campGoalName: 'church-ministry-and-church-presentations' }
                }"
                class="btn btn-primary btn--yellow"
              >
                <img src="@/assets/svgs/goal-planner/ic_play.svg" alt="Begin Goal Planning" />
                Begin Goal Planning
              </router-link>
            </div>
          </template>
          <template v-if="GOAL_CAT_NAME === 'RECEIPTS'" #table-total>
            <b-tr>
              <b-td><strong>Total</strong></b-td>
              <b-td
                class="text-right"
                v-for="(totalValue, fiscalYear) in getTotalCampGoalByCategoryKey(GOAL_CAT_KEY)"
                :key="fiscalYear"
                ><strong>{{ totalValue }}</strong></b-td
              >
            </b-tr>
          </template>
        </dashboard-table>
        <div class="planner-header">
          <h4>FINALIZE GOAL PLANNING <span>Lock Your Goal</span></h4>
        </div>
        <div class="g-panel">
          <div class="g-panel__lft">
            <p class="lft__width">
              Select the Lock Camp Goals button to finalize goal planning. Camp planning must be completed and finished
              by
              <strong>{{ getGoalPlanningCompletionDate }}.</strong>
            </p>
          </div>
          <span
            v-if="!isGoalsCompleted && !isCampGoalsLocked"
            tabindex="0"
            v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
            :title="'Camp cannot be locked as not all goals are completed.'"
          >
            <button
              type="button"
              :disabled="isLockGoalsButtonDisabled"
              @click="lockOrUnlockCampGoalsHandler"
              class="btn btn-primary btn--yellow"
            >
              {{ `${isCampGoalsLocked ? 'Unlock' : 'Lock'} Your Goal` }}
            </button>
          </span>
          <button
            v-else
            type="button"
            :disabled="isLockGoalsButtonDisabled"
            @click="lockOrUnlockCampGoalsHandler"
            class="btn btn-primary btn--yellow"
          >
            {{ `${isCampGoalsLocked ? 'Unlock' : 'Lock'} Your Goal` }}
          </button>
        </div>
      </template>
    </camp-goal-layout>
  </div>
</template>
<script>
/*eslint-disable*/
import CampGoalLayout from '@/components/camp-goal-planner/CampGoalLayout.vue'
import DashboardTable from '@/components/camp-goal-planner/DashboardTable.vue'
import { mapActions, mapGetters } from 'vuex'
import { YearRange } from '@/services/utils/YearRange.js'
import { PermittedGoalRouteNames } from '@/constants/PermittedGoalRouteNames'
import { GoalShortCodes } from '@/constants/GoalShortCodes.js'
import { FormatNumber } from '@/services/utils/FormatNumber.js'
import CampGoalService from '@/services/goals/CampGoal.js'
import { STATE_GOAL_PLANNER_MENU_HREF } from '@/constants/UserMenuHref.js'

export default {
  name: 'Dashboard',
  components: {
    DashboardTable,
    CampGoalLayout
  },
  data() {
    return {
      isDashboardLoading: true,
      GOAL_CATEGORY_KEYS: {
        RECEIPTS: 'receipts',
        PLACEMENTS: 'placements',
        PWTS: 'personal-worker-testaments',
        CHURCH_PRESENTATION: 'church-presentation',
        MEMBERSHIP: 'membership'
      }
    }
  },
  computed: {
    ...mapGetters({
      getDashboardData: 'campGoalPlanner/getDashboardData',
      isCampGoalPlanningWindowOpen: 'campGoalPlanner/isCampGoalPlanningWindowOpen',
      hasErrorDashboard: 'campGoalPlanner/hasErrorDashboard',
      loadingStatus: 'menu/loadingStatus',
      officerToolbarSelected: 'user/officerToolbarSelected',
      userLanguageKey: 'user/userLanguageKey',
      isStateGoalPlanningWindowOpen: 'stateGoalPlanner/isStateGoalPlanningWindowOpen',
      isInMenu: 'menu/isInMenu'
    }),
    isStateLocked() {
      return this.getDashboardData.IsStateLocked
    },
    isUserHasStateGoalPlannerAccessInEditMode() {
      return this.isInMenu(STATE_GOAL_PLANNER_MENU_HREF) && this.isStateGoalPlanningWindowOpen
    },
    isCampGoalReadOnly() {
      //NOTE: if user has state level role then should let user be in edit mode
      // Goals are locked
      if (this.isCampGoalsLocked) {
        return true
      } else if (!this.isUserHasStateGoalPlannerAccessInEditMode && !this.isCampGoalPlanningWindowOpen) {
        // login user is a camp leader and state window open and camp window is closed
        return true
      } else if (this.isUserHasStateGoalPlannerAccessInEditMode && !this.isCampGoalPlanningWindowOpen) {
        // login user is a state leader and state window open and camp window is closed
        return false
      }
      // when camp window is open
      return false
    },
    isGoalsCompleted() {
      return (
        this.getDashboardData.hasOwnProperty('GoalsPercentComplete') &&
        this.getDashboardData.GoalsPercentComplete === 100
      )
    },
    campName() {
      return this.officerToolbarSelected.camp_name
    },
    campKey() {
      return this.officerToolbarSelected.camp
    },
    stateOrgKey() {
      return this.officerToolbarSelected.country_state
    },
    isLockGoalsButtonDisabled() {
      if (this.isStateLocked) {
        return true
      }
      if (!this.isGoalsCompleted && !this.isCampGoalsLocked) {
        return true
      } else if (!this.isUserHasStateGoalPlannerAccessInEditMode && !this.isCampGoalPlanningWindowOpen) {
        // user does not have access to State Goal Planner and  Camp Goal Planning Window is closed
        return true
      }
      return false
    },
    currentFiscalYear() {
      return !this.getDashboardData.hasOwnProperty('CampInfo')
        ? new Date().getFullYear() + 1
        : parseInt(this.getDashboardData.CampInfo.GoalYear)
    },
    planningFiscalYear() {
      return parseInt(this.getDashboardData.CampInfo.GoalYear)
    },
    isCampGoalsLocked() {
      return this.getDashboardData.CampInfo.IsGoalsLocked
    },
    goalsPercentComplete() {
      return this.getDashboardData.GoalsPercentComplete
    },
    getGoalPlanningCompletionDate() {
      return this.getDashboardData.PlanningCompletionDate
    },
    getTotalCampGoalByCategoryKey() {
      return GOAL_CATEGORY_KEY => {
        let currentFYTotal = 0
        let lastFYTotal = 0
        let secondLastFYTotal = 0
        let thirdLastFYTotal = 0

        const isCategoryKeyOfReceipts = GOAL_CATEGORY_KEY === this.GOAL_CATEGORY_KEYS.RECEIPTS
        const goalsData = this.getDashboardData.CampGoalsData.find(
          itm =>
            itm &&
            itm.Name &&
            itm.Name.toLowerCase()
              .split(' ')
              .join('-') === GOAL_CATEGORY_KEY
        )

        if (!goalsData) {
          return []
        }

        goalsData &&
          goalsData.hasOwnProperty('Goals') &&
          goalsData.Goals.forEach(goal => {
            currentFYTotal += goal.PlannedGoal
            lastFYTotal += goal.FY1Goal
            secondLastFYTotal += goal.ActualFY2Goal
            thirdLastFYTotal += goal.ActualFY3Goal
          })

        return {
          currentFYTotal: this.formatTotalAmount(currentFYTotal, isCategoryKeyOfReceipts),
          lastFYTotal: this.formatTotalAmount(lastFYTotal, isCategoryKeyOfReceipts),
          secondLastFYTotal: this.formatTotalAmount(secondLastFYTotal, isCategoryKeyOfReceipts),
          thirdLastFYTotal: this.formatTotalAmount(thirdLastFYTotal, isCategoryKeyOfReceipts)
        }
      }
    },
    getLastThreeFYs() {
      const currentFY = this.getDashboardData.CampInfo.GoalYear
      return {
        lastFY: YearRange(currentFY - 1),
        secondLastFY: YearRange(currentFY - 2),
        thirdLastFY: YearRange(currentFY - 3)
      }
    },
    getTableGoalCategory() {
      return GOAL_CATEGORY_KEY => {
        const item = this.getDashboardData.CampGoalsData.find(
          itm =>
            itm.Name.toLowerCase()
              .split(' ')
              .join('-') === GOAL_CATEGORY_KEY
        )
        if (item) {
          return item.Name
        } else {
          return null
        }
      }
    },
    getDashboardTableData() {
      return GOAL_CATEGORY_KEY => {
        const GOAL_DATA = this.getDashboardData.CampGoalsData.find(
          itm =>
            itm.Name.toLowerCase()
              .split(' ')
              .join('-') === GOAL_CATEGORY_KEY
        )

        if (!GOAL_DATA) {
          return []
        }

        let tempArr = []
        GOAL_DATA.Goals.forEach(
          ({ GoalName, ShortCode, PlannedGoal, FY1Goal, ActualFY2Goal, ActualFY3Goal, IsModified }) => {
            tempArr.push({
              goalNameWithStatus: GoalName,
              goalAction: {
                routePath: this.getRightRoutePathName(ShortCode)
              },
              isGoalCompleted: IsModified,
              isCampLocked: this.isCampGoalsLocked,
              shortCode: ShortCode,
              campGoal: this.formatAmount(PlannedGoal, ShortCode),
              lastFYGoal: this.formatAmount(FY1Goal, ShortCode),
              secondLastFYGoal: this.formatAmount(ActualFY2Goal, ShortCode),
              thirdLastFYGoal: this.formatAmount(ActualFY3Goal, ShortCode)
            })
          }
        )

        return tempArr
      }
    }
  },
  methods: {
    ...mapActions({
      setCampGoalDashboardData: 'campGoalPlanner/setCampGoalDashboardData',
      downloadCampLockGoalsReport: 'campGoalPlanner/downloadCampLockGoalsReport',
      campGoalsLockOrUnlock: 'campGoalPlanner/campGoalsLockOrUnlock',
      setLoadingStatus: 'menu/setLoadingStatus'
    }),
    async lockOrUnlockCampGoalsHandler() {
      if (!this.isLockGoalsButtonDisabled) {
        const response = await this.campGoalsLockOrUnlock({
          campKey: this.campKey,
          fiscalYear: this.planningFiscalYear,
          isUserWantsToLockGoals: !this.isCampGoalsLocked,
          langKey: this.userLanguageKey
        })
        if (response === 'Success') {
          await this.setCampGoalDashboardData({
            campKey: this.campKey,
            langKey: this.userLanguageKey,
            stateOrgKey: this.stateOrgKey
          })
        }
      }
    },

    getRightRoutePathName(goalShortCode) {
      const campGoalRoutePaths = [
        [GoalShortCodes.ChurchMinistry, PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation],
        [GoalShortCodes.GideonCard, PermittedGoalRouteNames.GideonCard],
        [GoalShortCodes.FaithFund, PermittedGoalRouteNames.FaithFund],
        [GoalShortCodes.BarnabasFund, PermittedGoalRouteNames.FaithFund],
        [GoalShortCodes.AuxiliaryScripture, PermittedGoalRouteNames.AuxiliaryScripture],
        [GoalShortCodes.CampDesignated, PermittedGoalRouteNames.CampDesignated],
        [GoalShortCodes.GideonPlacements, PermittedGoalRouteNames.Placements],
        [GoalShortCodes.AuxiliaryPlacements, PermittedGoalRouteNames.Placements],
        [GoalShortCodes.GideonPWTs, PermittedGoalRouteNames.PersonalWorkersTestaments],
        [GoalShortCodes.AuxiliaryPWTs, PermittedGoalRouteNames.PersonalWorkersTestaments],
        [GoalShortCodes.GideonMembership, PermittedGoalRouteNames.Membership],
        [GoalShortCodes.AuxiliaryMembership, PermittedGoalRouteNames.Membership],
        [GoalShortCodes.ChurchPresentation, PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation]
      ]

      const campPageGoalRoutePaths = new Map(campGoalRoutePaths)
      return campPageGoalRoutePaths.get(goalShortCode)
    },
    formatAmount(value, shortCode) {
      if (shortCode !== undefined && shortCode !== '') {
        const isGoalDollarValue = CampGoalService.isGoalDollar(shortCode)
        return isGoalDollarValue ? CampGoalService.prependDollarSign(FormatNumber(value)) : FormatNumber(value)
      }
      return value
    },
    formatTotalAmount(value, isReceiptsValue) {
      return isReceiptsValue ? CampGoalService.prependDollarSign(FormatNumber(value)) : FormatNumber(value)
    }
  },
  watch: {
    campName: {
      async handler() {
        await this.setCampGoalDashboardData({
          campKey: this.campKey,
          langKey: this.userLanguageKey,
          stateOrgKey: this.stateOrgKey
        })
      },
      deep: true
    }
  },
  async created() {
    await this.setCampGoalDashboardData({
      campKey: this.campKey,
      langKey: this.userLanguageKey,
      stateOrgKey: this.stateOrgKey
    })
  }
}
</script>
<style lang="scss">
.camp-lock-text {
  color: #155724;
  font-size: 25px;
  margin-left: 5px;
}

.main-heading {
  color: #000;
  font-family: 'DIN 1451 W01 Engschrift', sans-serif;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 48px;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .badge {
    font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-transform: none;
  }
}

.planner-header {
  margin: 30px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin-bottom: 0;

    span {
      font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
      font-size: 20px;
      font-weight: 700;
      color: #747474;
      text-transform: capitalize;
      display: inline-flex;
      margin-left: 15px;
    }
  }

  .planner-header__lft {
    display: flex;

    a {
      &:first-child {
        margin-right: 13px;
      }
    }

    .btn {
      padding: 11px 13px;
      text-transform: capitalize;

      img {
        margin-right: 5px;
      }
    }
  }
}

.g-panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 38px;

  .g-panel__lft {
    display: flex;
    flex-direction: column;
    padding-right: 10px;

    p {
      font-style: italic;
      color: #747474;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 10px;
      font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        font-size: 16px;
        line-height: 22px;
        font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
        color: #000;
        font-weight: 700;
        list-style: none;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .lft__width {
      font-size: 20px;
      font-style: normal;
      max-width: 670px;
      line-height: 28px;
    }
  }

  .g-panel__rgt {
    display: flex;
    align-items: flex-start;

    .btn {
      padding: 11px 13px;
      font-size: 15px;
      line-height: 22px;
      text-transform: capitalize;

      &:first-child {
        margin-right: 12px;
      }

      img {
        margin-right: 3px;
      }
    }
  }
}

.card {
  &.card--mt {
    margin-top: 70px;
  }
}

.badge {
  position: relative;
  padding: 0.35rem 0.75rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: inline-block;
  margin-left: 10px;
  font-size: 13px;
  font-weight: normal;
}

.badge--success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.btn {
  &.btn--lgt-blue {
    background: #036f88;
  }

  &.btn--yellow {
    background: #fbc301;
    color: #fff;

    &:hover {
      background: #003946;
    }
  }

  &.btn--yellow:disabled {
    cursor: not-allowed;
  }

  &.btn--sm {
    padding-left: 20px;
    padding-right: 20px;
    text-transform: none;
  }
}

.warning-icon {
  width: 30px;
  height: 30px;
  padding-top: 10px;
}
</style>
